<template>
  <v-container fluid>
    <!--Breadcrumbs--> 
    <Breadcrumbs :breadcrumbs="breadcrumbs"/>

    <data-table 
      :headers="headers"
      :data="data"
      :loading="loading"
      :pageNumber="pageNumber"
      :paginate="paginate"
      :options="options"
      >
        <component :is="headerChildComponent" :refreshData="handleRefreshData"></component>
      </data-table>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { itemsSpan } from '../helpers/index';
import Breadcrumbs from '../components/common/Breadcrumbs.vue';
import DataTable from '../components/common/DataTable.vue';
import DataTableHeader from '../components/common/DataTableHeader.vue';
export default {
  components:{
    Breadcrumbs,
    DataTable,
    DataTableHeader
  },
  data(){
    return{
        breadcrumbs: [
            {
                text: 'Dashboard',
                href: '/',
            },
            {
                text: 'Articles',
                href: '/articles',
            },
        ],
        headers: [
            { text: 'Created at', value: 'created_at' },
            { text: 'Article name', value: 'name' },
            { text: 'Report keyword', value: 'keyword' },
            { text: 'Readability', value: 'readability' },
            { text: 'Word count', value: 'word_count' },
            { text: 'Content Grade', value: 'content_grade' },
        ],
        headerChildComponent: 'DataTableHeader',
        options:{
          contentGradeChips: ['content_grade'],
          timestamps: [ 'created_at' ]
        }
    }
  },
  computed: {
    ...mapState('articles', ['data', 'pageNumber', 'loading']),
    itemsSpan,
    articlesPageNumber: {
        get () {
            return this.pageNumber
        },
        set (value) {
            this.UPDATE_PAGE_NUMBER(value)
        }
    }
  },
  methods:{
    ...mapActions('articles', ['getArticles']),
    ...mapMutations('articles', ['UPDATE_PAGE_NUMBER']),
    paginate(pageNumber){
      this.articlesPageNumber = pageNumber
      this.getArticles();

    },
    handleRefreshData(){
      this.UPDATE_PAGE_NUMBER(1)
      this.getArticles()
    },
  },
  
  created(){
    this.getArticles({
      page: 1
    })
  }
}
</script>